import React from "react"
import * as S from "./updated-header.styles.jsx"
import parse from "html-react-parser"
import { useTheme } from "@material-ui/core"
import useMediaQuery from "@material-ui/core/useMediaQuery"

const UpdatedHeader = data => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"))

  return (
    <S.Wrapper
      scrollSnap={data.scrollSnap}
      className={
        (data.applyMask ? "masked" : "") +
        " " +
        (data.textColor === "blue" ? "text-blue" : "")
      }
    >
      {data.desktop.videoBackground ? (
        <S.Video
          src={
            isMobile
              ? data.mobile.videoBackground.publicUrl
              : data.desktop.videoBackground.publicUrl
          }
          poster={
            isMobile
              ? data.mobile.backgroundImage?.publicUrl
              : data.desktop.backgroundImage?.publicUrl
          }
          playsInline
          muted
          autoPlay
          loop
        ></S.Video>
      ) : (
        <S.Image
          className="image"
          img={
            isMobile
              ? data.mobile.backgroundImage
              : data.desktop.backgroundImage
          }
        />
      )}
      <S.FloatingContainer>
        <S.ContentArea>
          <h1>{parse(data.title)}</h1>
          {data.content && <S.Content>{parse(data.content)}</S.Content>}
        </S.ContentArea>
      </S.FloatingContainer>
    </S.Wrapper>
  )
}
export default UpdatedHeader
