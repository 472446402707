import React, { useState } from "react"
import * as S from "./faqs-section.styles.jsx"
// import AccordionSummary from "@material-ui/core/AccordionSummary"
// import AccordionDetails from "@material-ui/core/AccordionDetails"
import parse from "html-react-parser"

const FaqsSection = ({ title, faqs }) => {
  // const [expanded, setExpanded] = useState(
  //   faqs && faqs[0] && faqs[0].title ? `panel ${faqs[0].title} 0` : false
  // )
  // const handleChange = panel => (event, isExpanded) => {
  //   setExpanded(isExpanded ? panel : false)
  // }
  if (!faqs) return null
  return (
    <S.Wrapper contained maxWidth="md">
      <S.Title>{title}</S.Title>
      {faqs.map((faqGroup, index) => {
        return (
          <div key={`group ${index}`} className="group">
            <S.GroupTitle>{parse(faqGroup.title)}</S.GroupTitle>
            {faqGroup.items.map((faq, index) => {
              return (
                <S.Details key={`panel ${faqGroup.title} ${index}`} open={index === 0 ? 'open' : ''}>
                  <summary><S.SummaryText>{parse(faq.title)}</S.SummaryText></summary>
                  
                  {faq.content && (
                    <S.DetailsText>{parse(faq.content)}</S.DetailsText>
                  )}
                </S.Details>
                // <S.CustomAccordion
                //   key={`panel ${faqGroup.title} ${index}`}
                //   expanded={expanded === `panel ${faqGroup.title} ${index}`}
                //   onChange={handleChange(`panel ${faqGroup.title} ${index}`)}
                // >
                //   <AccordionSummary style={{ marginBottom: "2em" }}>
                //     <S.SummaryText>{parse(faq.title)}</S.SummaryText>
                //   </AccordionSummary>
                //   <AccordionDetails>
                //     {faq.content && (
                //       <S.DetailsText>{parse(faq.content)}</S.DetailsText>
                //     )}
                //   </AccordionDetails>
                // </S.CustomAccordion>
              )
            })}
          </div>
        )
      })}
    </S.Wrapper>
  )
}
export default FaqsSection
