import styled from "styled-components"
// import Accordion from "@material-ui/core/Accordion"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"

export const Wrapper = styled(SectionWrapper)`
  padding-left: 2em;
  padding-right: 2em;
  padding-top: 20px;
  padding-bottom: 86px;
  max-width: 700px;
  margin: auto;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    padding: 1.5em 1.1em;
  }

  .group:not(:last-child) {
    margin-bottom: 4rem;
  }
`

// export const CustomAccordion = styled(Accordion)`
//   box-shadow: none;
//   background-color: transparent;
//   &:before {
//     display: none;
//   }
//   .MuiAccordionDetails-root {
//     padding-left: 0;
//   }
//   .MuiButtonBase-root {
//     padding-left: 0;
//   }
//   .MuiAccordionSummary-content {
//     margin: 0;
//   }

//   .MuiAccordionSummary-root.Mui-expanded {
//     min-height: 0;
//     margin-bottom: 1.2em !important;
//   }
// `
export const Title = styled.h2`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-weight: 400;
  font-size: ${({ theme }) => theme.typography.pxToRem(64)};
  line-height: 60px;
  text-align: center;
  letter-spacing: -1px;
  margin-bottom: 1em;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(36)};
    line-height: 46px;
  }
  ${({ theme }) => theme.breakpoints.down("xs")} {
    text-align: left;
  }
`
export const GroupTitle = styled.h4`
  padding-bottom: 4px;
  margin-bottom: 1.5em;
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  letter-spacing: 0.04em;
  text-transform: uppercase;
  font-weight: 400;
  sup {
    font-size: 0.6em;
    top: 0;
  }
  &::after {
    content: "";
    display: block;
    height: 2px;
    margin-top: 1rem;
    background: linear-gradient(to right, #799fdf 0, #50d9b5 59%, #80dc94);
  }
`

export const Details = styled.details`
  margin-bottom: 2em;

  & > summary {
    list-style: none;

    &::marker,
    &::-webkit-details-marker {
      display: none;
    }

    cursor: pointer;
  }
`

export const SummaryText = styled.h3`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(28)};
  max-width: 600px;
  line-height: 1.13888em;
  letter-spacing: 0.5px;
  sup {
    font-size: 0.5em;
    line-height: 0;
    top: -0.5em;
    position: relative;
  }
`

export const DetailsText = styled.div`
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  font-family: ${({ theme }) => theme.fonts.tertiary};
  letter-spacing: 0.15px;
  line-height: 1.3em;
  padding-top: 1.5rem;
  sup {
    font-size: 0.9em;
    line-height: 0;
    top: 0;
    position: relative;
  }
  sub {
    vertical-align: bottom;
    position: relative;
    font-size: 0.9em;
    bottom: -5px;
  }
  p {
    margin: 0 0 1.5em 0;
    line-height: 1.5em;
    font-size: ${({ theme }) => theme.typography.pxToRem(16)};
    font-weight: 400;
    font-family: inherit;

    &:last-child {
      margin-bottom: 0;
    }
  }
`
